import {legacy_createStore as createStore, compose, applyMiddleware} from 'redux';
import sagaMiddlewareFactory from 'redux-saga';
import rootSaga from "./rootSaga";
import rootReducer from "./rootReducer";

const configureStore = () => {
    const sagaMiddleware = sagaMiddlewareFactory();

    // Настройка для включения дебаггера
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
