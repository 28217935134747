import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_CONFIG = 'config/REQUEST_CONFIG';
export const REQUEST_CONFIG_FAILED = 'config/REQUEST_CONFIG_FAILED';
export const RECEIVE_CONFIG = 'config/RECEIVE_CONFIG';

// Action Creators
export const requestConfig = createAction(REQUEST_CONFIG);
export const receiveConfigFailed = createAction(REQUEST_CONFIG_FAILED);
export const receiveConfig = createAction(RECEIVE_CONFIG);
