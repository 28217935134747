const HOST = process.env.REACT_APP_API_URL;
const CLUB_ID = process.env.REACT_APP_CLUB_ID;
const SURVEY_ID = process.env.REACT_APP_SURVEY_ID;
export const ENDPOINTS = {
    // Команды
    CONFIG: HOST + `/club/${CLUB_ID}/config`,
    RESULTS: HOST + `/club/${CLUB_ID}/survey/${SURVEY_ID}/results`,
    SEND_OTP: email => HOST + `/club/${CLUB_ID}/survey/${SURVEY_ID}/verification?email=${email}`,
    VERIFY_OTP: HOST + `/club/${CLUB_ID}/survey/${SURVEY_ID}/verification`,
    GET_VOTE: jwt => HOST + `/club/${CLUB_ID}/survey/${SURVEY_ID}/vote?id=${jwt}`,
    SET_VOTE: HOST + `/club/${CLUB_ID}/survey/${SURVEY_ID}/vote`,
}
