import {isNotNil} from "ramda";
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {requestCodeVerifiedStatus, requestResults, requestSignature, requestVariant, requestVote} from "./manager";
import {
    receiveResults,
    receiveResultsFailed,
    receiveSignature,
    receiveVariant,
    receiveVerifyCode,
    receiveVote,
    REQUEST_RESULTS,
    REQUEST_SIGNATURE,
    REQUEST_VARIANT,
    REQUEST_VERIFY_CODE,
    REQUEST_VOTE,
    requestSignatureFailed,
    requestVariantFailed,
    requestVerifyCodeFailed,
    requestVoteFailed
} from "./actions";
import {getSelectedVariant, getVote} from "./selectors";
import parseError from "../../utils/parseError";

const fetchSurveyResultsSaga = function* () {
    try {
        const results = yield call(requestResults);
        yield put(receiveResults(results));
    } catch (error) {
        yield put(receiveResultsFailed(parseError(error)))
    }
}

const fetchVariantSaga = function* () {
    try {
        const variant_jwt = yield localStorage.getItem("survey_variant_jwt");
        if (isNotNil(variant_jwt)) {
            const {id} = yield call(requestVariant, variant_jwt);
            yield put(receiveVariant(id));
        } else {
            yield put(receiveVariant(null));
        }
    } catch (error) {
        yield put(requestVariantFailed(parseError(error)))
    }
}

const fetchSignatureSaga = function* ({payload}) {
    try {
        const {signature} = yield call(requestSignature, payload);
        yield put(receiveSignature(signature));
    } catch (error) {
        yield put(requestSignatureFailed(parseError(error)))
    }
}

const fetchCodeVerifyStatus = function* ({payload}) {
    try {
        const {code, token} = payload;
        const isVerified = yield call(requestCodeVerifiedStatus, code, token);
        yield put(receiveVerifyCode(isVerified));
    } catch (error) {
        yield put(requestVerifyCodeFailed(parseError(error)))
    }
}

const fetchVote = function* () {
    try {
        const {name, email, token} = yield select(getVote);
        const variant = yield select(getSelectedVariant);
        const {jwt, id, isAlreadyVoted} = yield call(requestVote, name, email, token, variant);
        const results = yield call(requestResults);
        yield localStorage.setItem('survey_variant_jwt', jwt);
        yield put(receiveVariant(id));
        yield put(receiveVote(isAlreadyVoted));
        yield put(receiveResults(results));
    } catch (error) {
        yield put(requestVoteFailed(parseError(error)))
    }
}

const surveySaga = function* () {
    yield all([
        yield takeLatest(REQUEST_VOTE, fetchVote),
        yield takeLatest(REQUEST_RESULTS, fetchSurveyResultsSaga),
        yield takeLatest(REQUEST_VARIANT, fetchVariantSaga),
        yield takeLatest(REQUEST_SIGNATURE, fetchSignatureSaga),
        yield takeLatest(REQUEST_VERIFY_CODE, fetchCodeVerifyStatus)
    ]);
}

export default surveySaga;
