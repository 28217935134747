import {F, T} from 'ramda';
import {handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {
    CHANGE_VOTE,
    CLOSE_REGISTER_FORM,
    CLOSE_SUCCESS_MODAL,
    FIRST_HAS_BEEN_OPENED,
    OPEN_REGISTER_FORM,
    OPEN_SUCCESS_MODAL,
    RECEIVE_RESULTS,
    RECEIVE_SIGNATURE,
    RECEIVE_VARIANT,
    RECEIVE_VERIFY_CODE,
    RECEIVE_VOTE,
    REQUEST_RESULTS,
    REQUEST_RESULTS_FAILED,
    REQUEST_SIGNATURE,
    REQUEST_SIGNATURE_FAILED,
    REQUEST_VARIANT,
    REQUEST_VARIANT_FAILED,
    REQUEST_VERIFY_CODE,
    REQUEST_VERIFY_CODE_FAILED,
    REQUEST_VOTE,
    REQUEST_VOTE_FAILED,
    SELECT_VARIANT,
    SET_USER_VERIFIED
} from './actions';

const selectedVariant = handleActions(
    {
        [SELECT_VARIANT]: (_, {payload}) => payload,
    },
    null,
);

const isFetchingVariant = handleActions(
    {
        [REQUEST_VARIANT]: T,
        [REQUEST_VARIANT_FAILED]: F,
        [RECEIVE_VARIANT]: F
    },
    false,
);

const variant = handleActions(
    {
        [REQUEST_VARIANT]: () => null,
        [RECEIVE_VARIANT]: (_, {payload}) => payload
    },
    null
);

const signature = handleActions(
    {
        [REQUEST_SIGNATURE]: () => null,
        [RECEIVE_SIGNATURE]: (_, {payload}) => payload
    },
    null,
);

const isFetchingSignature = handleActions(
    {
        [REQUEST_SIGNATURE]: T,
        [REQUEST_SIGNATURE_FAILED]: F,
        [RECEIVE_SIGNATURE]: F
    },
    false,
);

const isCodeVerified = handleActions(
    {
        [REQUEST_VERIFY_CODE]: () => false,
        [RECEIVE_VERIFY_CODE]: (_, {payload}) => payload
    },
    false,
);

const isFetchingVerifyCode = handleActions(
    {
        [REQUEST_VERIFY_CODE]: T,
        [REQUEST_VERIFY_CODE_FAILED]: F,
        [RECEIVE_VERIFY_CODE]: F
    },
    false,
);

const entities = handleActions(
    {
        [REQUEST_RESULTS]: () => [],
        [RECEIVE_RESULTS]: (_, {payload}) => payload
    },
    [],
);

const isFetchingResults = handleActions(
    {
        [REQUEST_RESULTS]: T,
        [REQUEST_RESULTS_FAILED]: F,
        [RECEIVE_RESULTS]: F
    },
    false,
);

const userVerified = handleActions(
    {
        [SET_USER_VERIFIED]: (_, {payload}) => payload,
    },
    false,
)

const isFetchingVote = handleActions(
    {
        [REQUEST_VOTE]: T,
        [REQUEST_VOTE_FAILED]: F,
        [RECEIVE_VOTE]: F
    },
    false,
);

const isAlreadyVoted = handleActions(
    {
        [REQUEST_VOTE]: F,
        [RECEIVE_VOTE]: (_, {payload}) => payload
    },
    false
);

const isVoted = handleActions(
    {
        [RECEIVE_VOTE]: T
    },
    false
);

const vote = handleActions(
    {
        [CHANGE_VOTE]: (state, {payload}) => ({
            ...state,
            [payload.name]: payload.value
        })
    },
    {
        name: '',
        email: '',
        emailVerified: false,
        otp: '',
        token: null
    },
);

const error = handleActions(
    {
        [REQUEST_VOTE]: () => null,
        [REQUEST_RESULTS]: () => null,
        [REQUEST_VARIANT]: () => null,
        [REQUEST_SIGNATURE]: () => null,
        [REQUEST_VERIFY_CODE]: () => null,
        [REQUEST_VOTE_FAILED]: (_, {payload}) => payload,
        [REQUEST_RESULTS_FAILED]: (_, {payload}) => payload,
        [REQUEST_VARIANT_FAILED]: (_, {payload}) => payload,
        [REQUEST_SIGNATURE_FAILED]: (_, {payload}) => payload,
        [REQUEST_VERIFY_CODE_FAILED]: (_, {payload}) => payload,
    },
    null,
);

const isFormModalOpen = handleActions(
    {
        [OPEN_REGISTER_FORM]: T,
        [CLOSE_REGISTER_FORM]: F
    },
    false
)

const isSuccessModalOpen = handleActions(
    {
        [OPEN_SUCCESS_MODAL]: T,
        [CLOSE_SUCCESS_MODAL]: F
    },
    false
)

const isFormHasBeenOpened = handleActions(
    {
        [FIRST_HAS_BEEN_OPENED]: T
    },
    false
);

export default combineReducers({
    userVerified,
    isVoted,
    vote,
    isAlreadyVoted,
    selectedVariant,
    variant,
    isFetchingVote,
    isFetchingVariant,
    isFetchingResults,
    isFetchingSignature,
    isFetchingVerifyCode,
    isCodeVerified,
    signature,
    entities,
    error,
    isFormModalOpen,
    isSuccessModalOpen,
    isFormHasBeenOpened
});
