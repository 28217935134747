import {applySpec, pipe, prop, propOr} from "ramda";
import axios from "redaxios";
import {ENDPOINTS} from "../../bootstap/endpoints";

const getConfigParams = (entity) =>
    pipe(
        prop('data'),
        applySpec({
            fundsRaised: prop('funds_raised'),
            isSurveyFinished: propOr(false, 'survey_is_finished'),
        }),
    )(entity);

export const apiCall = () => {
    return axios.get(ENDPOINTS.CONFIG)
        .then(response => {
            return getConfigParams(response.data);
        })
        .catch(err => {
            throw err;
        });
}
