import {call, put, takeLatest} from 'redux-saga/effects';
import {apiCall} from "./manager";
import {REQUEST_CONFIG, receiveConfig, receiveConfigFailed} from "./actions";
import parseError from "../../utils/parseError";

const fetchConfigSaga = function* () {
    try {
        const config = yield call(apiCall);
        yield put(receiveConfig(config));
    }
    catch(error) {
        yield put(receiveConfigFailed(parseError(error)))
    }
}

const configSaga = function* () {
    yield takeLatest(REQUEST_CONFIG, fetchConfigSaga);
}

export default configSaga;
