import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import './styles/index.scss';
import configureStore from "./store/configureStore";
import {requestConfig} from "./store/config";
import {requestResults, requestVariant} from "./store/survey";
import {HeaderFinal} from "./containers/Header/HeaderFinal";

const store = configureStore();

const App = () => {
    store.dispatch(requestConfig());
    store.dispatch(requestResults());
    store.dispatch(requestVariant());
    return (<Provider store={store}>
          {/*<Header/>*/}
          <HeaderFinal/>
          {/*<FavoriteClub/>*/}
          {/*<Goals/>*/}
          {/*<Survey/>*/}
          {/*<Footer/>*/}
      </Provider>);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
