import React from "react";
import b from './Header.module.scss'
import { ReactComponent as LogoVP } from './assets/logo/logo-vp.svg';
import { ReactComponent as LogoWinline } from './assets/logo/logo-wnln.svg';
import {Headline} from "../../components/headline/Headline";
import composition from "./assets/winners/vp-party.png";
import composition2x from "./assets/winners/vp-party@2x.png";

export const HeaderFinal = () => {
    return (
        <div className={`${b.container} ${b.containerFinal}`}>
            <div className={b.logosContainer}>
                <div className="medium-margin">
                    <div className="row">
                        <div className="column large-12">
                            <div className={b.logos}>
                                <a className={b.winlineLogo}
                                   target="_blank"
                                   href="https://clck.ru/36ERb3" rel="noreferrer">
                                    <LogoWinline />
                                </a>
                                <span className={b.splitter}></span>
                                <a className={b.partnerLogo} target="_blank" href="https://virtus.pro" rel="noreferrer">
                                    <LogoVP />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medium-margin">
                <div className="row">
                    <div className="column small-12 medium-12 large-12 position-relative">
                        <div className={b.preHeadlineContainer}>
                            <div className={b.tag}>
                                <span>Голосование завершено</span>
                            </div>
                        </div>
                        <div className={b.headlineContainer}>
                            <Headline className={b.headline}>
                                Выбор <br className="show-for-medium-only"/> сделан
                            </Headline>
                        </div>
                        <div className={`${b.composition}`}>
                            <img
                                src={composition} srcSet={`${composition2x} 2x`}
                                draggable="false"
                                alt=""
                            />
                        </div>
                        <div className={b.surveyResultsContainer}>
                            <div className={b.surveyResults}>
                                <div className={b.results}>
                                    <div className={b.variantPercentage}>71%</div>
                                    <div className={b.variantTitle}>
                                        Проголосовало<br/>за&nbsp;VIRTUS.PRO PARTY
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
