import {F, T} from 'ramda';
import {handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {RECEIVE_CONFIG, REQUEST_CONFIG, REQUEST_CONFIG_FAILED} from './actions';

const entity = handleActions(
    {
        [REQUEST_CONFIG]: () => null,
        [RECEIVE_CONFIG]: (_, {payload}) => payload
    },
    null,
);

const isFetching = handleActions(
    {
        [REQUEST_CONFIG]: T,
        [REQUEST_CONFIG_FAILED]: F,
        [RECEIVE_CONFIG]: F
    },
    false,
);

const error = handleActions(
    {
        [REQUEST_CONFIG]: () => null,
        [REQUEST_CONFIG_FAILED]: (_, {payload}) => payload
    },
    null,
);

export default combineReducers({
    isFetching,
    entity,
    error
});
