import {applySpec, pipe, prop} from "ramda";

const parseError = error => pipe(
    prop('data'),
    applySpec({
        code: prop('code'),
        message: prop('message'),
    }),
)(error);

export default parseError;
