import {all, call} from 'redux-saga/effects';
import {configSaga} from "./config";
import {surveySaga} from "./survey";

const rootSaga = function* () {
    yield all([
        call(configSaga),
        call(surveySaga),
    ]);
};

export default rootSaga;
