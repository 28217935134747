import {createAction} from 'redux-actions';

// Action Types
export const REQUEST_RESULTS = 'survey/REQUEST_RESULTS';
export const REQUEST_RESULTS_FAILED = 'survey/REQUEST_RESULTS_FAILED';
export const RECEIVE_RESULTS = 'survey/RECEIVE_RESULTS';
export const SELECT_VARIANT = 'survey/SELECT_VARIANT';
export const REQUEST_VARIANT = 'survey/REQUEST_VARIANT';
export const REQUEST_VARIANT_FAILED = 'survey/REQUEST_VARIANT_FAILED';
export const RECEIVE_VARIANT = 'survey/RECEIVE_VARIANT';
export const REQUEST_SIGNATURE = 'survey/REQUEST_SIGNATURE';
export const REQUEST_SIGNATURE_FAILED = 'survey/REQUEST_SIGNATURE_FAILED';
export const RECEIVE_SIGNATURE = 'survey/RECEIVE_SIGNATURE';
export const REQUEST_VERIFY_CODE = 'survey/REQUEST_VERIFY_CODE';
export const REQUEST_VERIFY_CODE_FAILED = 'survey/REQUEST_VERIFY_CODE_FAILED';
export const RECEIVE_VERIFY_CODE = 'survey/RECEIVE_VERIFY_CODE';
export const CHANGE_VOTE = 'survey/CHANGE_VOTE';
export const REQUEST_VOTE = 'survey/REQUEST_VOTE';
export const REQUEST_VOTE_FAILED = 'survey/REQUEST_VOTE_FAILED';
export const RECEIVE_VOTE = 'survey/RECEIVE_VOTE';
export const SET_USER_VERIFIED = 'survey/SET_USER_VERIFIED';
export const OPEN_REGISTER_FORM = 'survey/modal/OPEN_REGISTER_FORM';
export const CLOSE_REGISTER_FORM = 'survey/modal/CLOSE_REGISTER_FORM';
export const OPEN_SUCCESS_MODAL = 'survey/modal/OPEN_SUCCESS_MODAL';
export const CLOSE_SUCCESS_MODAL = 'survey/modal/CLOSE_SUCCESS_MODAL';
export const FIRST_HAS_BEEN_OPENED = 'survey/modal/FIRST_HAS_BEEN_OPENED';

// Action Creators
export const requestResults = createAction(REQUEST_RESULTS);
export const receiveResultsFailed = createAction(REQUEST_RESULTS_FAILED);
export const receiveResults = createAction(RECEIVE_RESULTS);
export const selectVariant = createAction(SELECT_VARIANT);
export const requestVariant = createAction(REQUEST_VARIANT);
export const requestVariantFailed = createAction(REQUEST_VARIANT_FAILED);
export const receiveVariant = createAction(RECEIVE_VARIANT);
export const requestSignature = createAction(REQUEST_SIGNATURE);
export const requestSignatureFailed = createAction(REQUEST_SIGNATURE_FAILED);
export const receiveSignature = createAction(RECEIVE_SIGNATURE);
export const requestVerifyCode = createAction(REQUEST_VERIFY_CODE);
export const requestVerifyCodeFailed = createAction(REQUEST_VERIFY_CODE_FAILED);
export const receiveVerifyCode = createAction(RECEIVE_VERIFY_CODE);
export const changeVoteFormField = createAction(CHANGE_VOTE);
export const requestVote = createAction(REQUEST_VOTE);
export const requestVoteFailed = createAction(REQUEST_VOTE_FAILED);
export const receiveVote = createAction(RECEIVE_VOTE);
export const setUserVerified = createAction(SET_USER_VERIFIED);
export const openFormModal = createAction(OPEN_REGISTER_FORM);
export const closeFormModal = createAction(CLOSE_REGISTER_FORM);
export const openSuccessModal = createAction(OPEN_SUCCESS_MODAL);
export const closeSuccessModal = createAction(CLOSE_SUCCESS_MODAL);
export const formHasBeenOpened = createAction(FIRST_HAS_BEEN_OPENED);
