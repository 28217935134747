import {applySpec, equals, map, pipe, prop} from "ramda";
import axios from "redaxios";
import {ENDPOINTS} from "../../bootstap/endpoints";

export const requestResults = () => {
    return axios.get(ENDPOINTS.RESULTS).then(response => {
        return pipe(
            prop('data'),
            map(
                applySpec({
                    id: prop('variantId'),
                    variantTitle: prop('variant'),
                    result: prop('result'),
                }),
            ),
        )(response.data);
    }).catch(err => {
        throw err;
    });
}

export const requestVariant = (jwt) => {
    return axios.get(ENDPOINTS.GET_VOTE(jwt)).then(response => {
        return pipe(
            prop('data'),
            applySpec({
                id: prop('variantId'),
            }),
        )(response.data);
    }).catch(err => {
        throw err;
    });
}

export const requestSignature = (email) => {
    return axios.get(ENDPOINTS.SEND_OTP(email)).then(response => {
        return pipe(
            prop('data'),
            applySpec({
                signature: prop('token'),
            }),
        )(response.data);
    }).catch(err => {
        throw err;
    });
}

export const requestCodeVerifiedStatus = (code, token) => {
    const data = new FormData();
    data.append('code', code);
    data.append('token', token);
    return axios.post(ENDPOINTS.VERIFY_OTP, data).then(response => {
        return equals(200, response.status);
    }).catch(err => {
        throw err;
    });
}

export const requestVote = (name, email, token, variant) => {
    const data = new FormData();
    data.append('email', email);
    data.append('token', token);
    data.append('vote', variant);
    data.append('person', name);
    return axios.post(ENDPOINTS.SET_VOTE, data).then(response => {
        return pipe(
            prop('data'),
            applySpec({
                jwt: prop('token'),
                id: prop('variantId'),
                isAlreadyVoted: prop('isAlreadyVoted'),
            }),
        )(response.data);
    }).catch(err => {
        throw err;
    });
}
